import store from '@/state/store'
export default [
  {
    path: '/',
    component: () => import('@/layouts/main'),
    children: [
      //============================ Preventa ======================
      {
        path: '/preventa/:codePresale/documentos',
        name: 'proyectos',
        meta: { authRequired: true },
        component: () => import('./views/presale/documents/index')
      },
      //============================ Proyectos ======================
      {
        path: '',
        name: 'proyectos',
        meta: { authRequired: true },
        component: () => import('./views/proyectos/index')
      },
      // ============================== Seguimiento HH =========================
      {
        path: 'tracking-hh',
        name: 'traking_hh',
        meta: { authRequired: false },
        component: () => import('./views/tracking/index')
      },
       // ============================== Modulo Talana / SAP =========================
       {
        path: 'data/talana-sap',
        name: 'talana-sap',
        meta: { authRequired: true },
        component: () => import('./views/talana-sap/index')
      },
      // ============================== Time Sheet User =========================
      {
        path: 'time-sheet',
        name: 'time_sheet',
        meta: { authRequired: false },
        component: () => import('./views/timeSheetUser/index')
      },

      // ============================== Abastecimiento =========================
      {
        path: '/supplying',
        name: 'supplying',
        meta: { authRequired: false },
        component: () => import('./views/supplying/index')
      },
      {
        path: '/supplying/dashboard',
        name: 'supplying-dashboard',
        meta: { authRequired: false },
        component: () => import('./views/supplying/dashboard/index')
      },
      {
        path: '/supplying/form/materials',
        name: 'supplying-form-materials',
        meta: { authRequired: false },
        component: () => import('./views/supplying/formMaterials/index')
      },
      {
        path: '/supplying/form/suppliers',
        name: 'supplying-form-suppliers',
        meta: { authRequired: false },
        component: () => import('./views/supplying/formSuppliers/index')
      },
      {
        path: '/supplying/requests-transfer/date-delivery',
        name: 'requests-transfer-date-delivery',
        meta: { authRequired: false },
        component: () => import('./views/supplying/requestsTransfer/dateDelivery/index')
      },
      {
        path: '/supplying/cd/requests-transfer',
        name: 'cd-requests-transfer',
        meta: { authRequired: false },
        component: () => import('./views/supplying/requestsTransfer/viewRequestTransfersCd/index')
      },

      // ============================== Modulo Compras =========================
      {
        path: '/supplying/purchase-requests',
        name: 'purchase-requests',
        meta: { authRequired: false },
        component: () => import('./views/supplying/purchaseModule/purchaseRequests/index')
      },
      {
        path: '/supplying/purchase-module/materials',
        name: 'purchase-module-materials',
        meta: { authRequired: false },
        component: () => import('./views/supplying/purchaseModule/materials/index')
      },
      {
        path: '/supplying/purchase-module/suppliers',
        name: 'purchase-module-suppliers',
        meta: { authRequired: false },
        component: () => import('./views/supplying/purchaseModule/suppliers/index')
      },
      {
        path: '/supplying/purchase-module/suppliers',
        name: 'purchase-module-suppliers',
        meta: { authRequired: false },
        component: () => import('./views/supplying/purchaseModule/suppliers/index')
      },
      // ============================== Reportes =========================
      {
        path: '/supplying/reports/products',
        name: 'supplying-reports-products',
        meta: { authRequired: false },
        component: () => import('./views/supplying/reports/ReportProducts')
      },
      {
        path: '/supplying/reports/stock',
        name: 'supplying-reports-stock',
        meta: { authRequired: false },
        component: () => import('./views/supplying/reports/ReportStock')
      },
      // ============================== Abastecimiento resumen ==================
      {
        path: '/supplying-summary',
        name: 'supplying-summary',
        meta: { authRequired: false },
        component: () => import('./views/supplying-summary/index')
      },
      {
        path: '/supplying-summary/:codigoLugarInstalacion',
        name: 'supplying-summary-show',
        meta: { authRequired: false },
        component: () => import('./views/supplying-summary/show')
      },
      // ============================== Bodega Movil ===========================
      {
        path: '/supplying/mobile-warehouses/requests',
        name: 'mobile-warehouses-requests',
        meta: { authRequired: false },
        component: () => import('./views/supplying/mobileWarehouses/requests/index')
      },
      {
        path: '/supplying/mobile-warehouses/material-transfers',
        name: 'bodega_movil_transferencias',
        meta: { authRequired: false },
        component: () => import('./views/supplying/mobileWarehouses/transfers/index')
      },
      {
        path: '/supplying/mobile-warehouses/stock',
        name: 'mobile-warehouses-stock',
        meta: { authRequired: false },
        component: () => import('./views/supplying/mobileWarehouses/stock/index')
      },
      
      // ============================== Usuarios ===============================
      {
        path: '/usuarios/usuario',
        name: 'usuario',
        meta: { authRequired: true },
        component: () => import('./views/usuarios/usuario')
      },
      {
        path: '/usuarios/rol',
        name: 'rol',
        meta: { authRequired: true },
        component: () => import('./views/usuarios/rol')
      },
      {
        path: '/usuarios/moduloPermiso',
        name: 'moduloPermiso',
        meta: { authRequired: true },
        component: () => import('./views/usuarios/modulo-permiso')
      },
      {
        path: '/usuarios/cargo',
        name: 'cargo',
        meta: { authRequired: true },
        component: () => import('./views/usuarios/cargo')
      },
      {
        path: '/usuarios/perfil',
        name: 'perfil',
        meta: { authRequired: true },
        component: () => import('./views/usuarios/perfil')
      },
      {
        path: '/usuarios/usuarioBodega',
        name: 'usuarioBodega',
        meta: { authRequired: true },
        component: () => import('./views/usuarios/usuario-bodega-movil')
      },
      // ============================== Fin Usuarios ===============================

      // ============================== Smarsheets =========================
      {
        path: '/smartsheet/general',
        name: 'nested_webpage_c_i',
        meta: { authRequired: false },
        component: () => import('./views/smartsheet/GeneralSmartsheet')
      },
      {
        path: '/smartsheet/residential',
        name: 'nested_webpage_residencial',
        meta: { authRequired: false },
        component: () => import('./views/smartsheet/ResidentialSmartsheet')
      },
      {
        path: '/smartsheet/commercial-industrial',
        name: 'nested_webpage_company',
        meta: { authRequired: false },
        component: () => import('./views/smartsheet/IndustrialCommercialSmartsheet')
      },
      {
        path: '/smartsheet/pmgd',
        name: 'nested_webpage_pmgd',
        meta: { authRequired: false },
        component: () => import('./views/smartsheet/PmgdSmartsheet')
      },

      // ============================== Inicio Lugares Instalacion ===============================
      {
        path: '/proyectos/lugaresInstalacion/:codigoProyecto',
        name: 'lugaresInstalacion',
        meta: { authRequired: true },
        component: () => import('./views/proyectos/lugarInstalacion/lugares-instalacion')
      },

      {
        path: '/proyectos/lugarInstalacion/:codigoLugarInstalacion/:codigoProyecto',
        name: 'lugarInstalacion',
        meta: { authRequired: true },
        component: () => import('@/views/proyectos/lugarInstalacion/LugarInstalacionView.vue'),
        children: [
          {
            path: '',
            name: 'lugarInstalacion',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarInformacionTecnica.vue')
          },
          {
            path: 'comentarios',
            name: 'comentarios',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarComentarios.vue')
          },
          {
            path: 'ubicacion',
            name: 'ubicacionLugarInstalacion',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarUbicacion.vue')
          },
          {
            path: 'informacionTecnica',
            name: 'informacionTecnica',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarInformacionTecnica.vue')
          },

          {
            path: 'contactos',
            name: 'contactos',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarContactos.vue')
          },

          {
            path: 'galeriaImagenes',
            name: 'galeriaImagenes',
            meta: { authRequired: true },
            component: () => import('@/components/proyectos/lugarInstalacion/LugarGaleria.vue')
          },
          {
            path: 'documentos',
            name: 'documentos',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarDocumentos.vue')
          },
          // {
          //   path: 'documentos',
          //   name: 'documentos',
          //   meta: { authRequired: true },
          //   component: () =>
          //     import('@/router/views/projects/documents/index.vue')
          // },
          {
            path: 'links',
            name: 'custom_link',
            meta: { authRequired: false },
            component: () => import('@/components/proyectos/lugarInstalacion/links/index.vue')
          },
          {
            path: 'smartsheet',
            name: 'nested_webpage_installation_point',
            meta: { authRequired: false },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/smartsheet/index.vue')
          },
          {
            path: 'crm',
            name: 'crm',
            meta: { authRequired: false },
            component: () => import('@/components/proyectos/lugarInstalacion/crm/index.vue')
          },
          {
            path: 'cubicles',
            name: 'cubicles',
            meta: { authRequired: false },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/cubicles/index.vue')
          },
          {
            path: 'solicitudMateriales',
            name: 'solicitudMateriales',
            meta: { authRequired: true },
            component: () =>
              import('@/components/proyectos/lugarInstalacion/LugarSolicitudMaterial.vue')
          },
          {
            path: 'informacionCompleta',
            name: 'informacionCompleta',
            meta: { authRequired: true },
            component: () => import('./views/proyectos/informacionCompleta')
          }
        ]
      },
      // ============================== Fin Lugares Instalacion ===============================

      {
        path: '/tutoriales',
        name: 'tutorial',
        meta: { authRequired: false },
        component: () => import('./views/tutorial/Tutorial')
      },

      // ============================== Proyectos ===============================
      {
        path: '/proyectos/informeDiario/:codigoLugarInstalacion/:codigoProyecto',
        name: 'informeDiario',
        meta: { authRequired: true },
        component: () => import('./views/proyectos/informeDiario/informe-diario')
      },
      {
        path: '/proyectos/bitacora/:codigoLugarInstalacion/:codigoProyecto',
        name: 'bitacora',
        meta: { authRequired: true },
        component: () => import('./views/proyectos/bitacora/bitacora')
      },
      // ============================== Mantenedores ===============================
      {
        path: '/mantenedores/matrizAprobacion',
        name: 'matrizAprobacion',
        meta: { authRequired: false },
        component: () => import('./views/mantenedores/approvalMatrix/index')
      },
      {
        path: '/mantenedores/trabajadores',
        name: 'empleados',
        meta: { authRequired: false },
        component: () => import('./views/mantenedores/employees/index')
      },
      // {
      //   path: '/mantenedores/actividadTipoProyectoConfiguracion', // OJO, no funciona
      //   name: 'actividadTipoProyectoConfiguracion',
      //   meta: { authRequired: true },
      //   component: () =>
      //     import(
      //       './views/mantenedores/actividadTipoProyectoConfiguracion/actividad-tipo-proyecto-configuracion'
      //     )
      // },
      {
        path: '/mantenedores/tipoDocumento',
        name: 'tipoDocumento',
        meta: { authRequired: true },
        component: () => import('./views/mantenedores/documentos/tipo-documento')
      },
      {
        path: '/mantenedores/nombreDocumento',
        name: 'nombreDocumento',
        meta: { authRequired: true },
        component: () => import('./views/mantenedores/documentos/nombre-documento')
      },
      // {
      //   path: '/mantenedores/tipoProyecto',
      //   name: 'tipoProyecto',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/tipo-proyecto')
      // },
      // {
      //   path: '/mantenedores/tipoCliente',
      //   name: 'tipoCliente',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/tipo-cliente')
      // },
      // {
      //   path: '/mantenedores/tipoVenta',
      //   name: 'tipoVenta',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/tipo-venta')
      // },
      // {
      //   path: '/mantenedores/tipoInstalacion',
      //   name: 'tipoInstalacion',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/tipo-instalacion')
      // },
      // {
      //   path: '/mantenedores/normativa',
      //   name: 'normativa',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/normativa')
      // },
      // {
      //   path: '/mantenedores/etapaProyecto',
      //   name: 'etapaProyecto',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/etapa-proyecto')
      // },
      // {
      //   path: '/mantenedores/subEtapaProyecto',
      //   name: 'subEtapaProyecto',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/sub-etapa-proyecto')
      // },
      {
        path: '/mantenedores/appFlexEnergetica', // Ojo, no funciona
        name: 'appFlexEnergetica',
        meta: { authRequired: true },
        component: () => import('./views/mantenedores/proyectos/app-flex-energetica')
      },
      // {
      //   path: '/mantenedores/actividad', // Ojo, no funciona
      //   name: 'actividad',
      //   meta: { authRequired: true },
      //   component: () => import('./views/mantenedores/proyectos/actividad')
      // },
      {
        path: '/mantenedores/templateGantt',
        name: 'templateGantt',
        meta: { authRequired: true },
        component: () => import('./views/mantenedores/proyectos/template-gantt')
      },
      {
        path: '/mantenedores/templateGantt/gantt/:templateId?',
        name: 'templateGanttGantt',
        meta: { authRequired: true },
        component: () => import('./views/mantenedores/proyectos/gantt')
      },
      {
        path: '/mantenedores/correo/notificacion/formularios',
        name: 'mantenedorCorreoFormularios',
        meta: { authRequired: false },
        component: () => import('./views/mantenedores/formsEmail/index')
      },
      {
        path: '/mantenedores/documentos/carpetas',
        name: 'mantenedorDocumentosCarpetas',
        meta: { authRequired: false },
        component: () => import('./views/documents/maintaners/index')
      },
      // ============================== Fin Mantenedores ===============================

      // ============================== Solicitud Materiales ===============================
      {
        path: '/materiales/solicitudMaterial',
        name: 'solicitudMaterial',
        meta: { authRequired: true },
        component: () => import('./views/materiales/solicitud-material')
      },
      {
        path: '/materiales/aprobar/solicitudMaterial',
        name: 'aprobarSolicitudMaterial',
        meta: { authRequired: false },
        component: () => import('./views/requests-materials/approve/index')
      },
      {
        path: '/bodegamovil/solicitudes',
        name: 'solicituidesBodegaMovil',
        meta: { authRequired: true },
        component: () => import('./views/bodega-movil/solicitudes-bodega-movil')
      },
      {
        path: '/bodegamovil/inventario',
        name: 'inventarioBodega',
        meta: { authRequired: true },
        component: () => import('./views/bodega-movil/inventario-bodega')
      },
      // ============================== Fin Solicitud Materiales ===============================
      // ============================== Modulos SAP ===============================
      {
        path: '/sap/request-materials',
        name: 'sapRequestMaterials',
        meta: { authRequired: false },
        component: () => import('./views/materialsSap/index')
      }, 
      {
        path: '/my-request-materials',
        name: 'myRequestMaterials',
        meta: { authRequired: false },
        component: () => import('./views/requests-materials/user/index')
      }, 
      {
        path: '/request-materials/return-materials',
        name: 'returnMaterials',
        meta: { authRequired: false },
        component: () => import('./views/requests-materials/return/index')
      }, 
      // ============================== Modulos Generales ===============================
      {
        path: '/configuraciones',
        name: 'settings',
        meta: { authRequired: false },
        component: () => import('./views/settings/Index')
      }, 
    ]
  },

  {
    path: '/proyectos/actividadProyecto/:codigoLugarInstalacion/:codigoProyecto',
    name: 'actividadesProyecto',
    meta: { authRequired: true },
    component: () => import('./views/proyectos/actividadProyecto/actividades-proyecto')
  },

  {
    path: '/proyectos/gantt/:codigoLugarInstalacion/:codigoProyecto',
    name: 'gantt',
    meta: { authRequired: true },
    component: () => import('./views/proyectos/gantt/gantt')
  },

  // // ============================== Usuarios ===============================
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/loginV2'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('./views/account/change-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      }
    }
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          store.dispatch('auth/logOut')
        } else {
          store.dispatch('auth/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'default' } : { ...routeFrom })
      }
    }
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '/proyectos/proyectos',
    redirect: '/'
  },

  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('./views/maintenance/index'),
  },
]
