import BaseService from "../../services/base_service"
import ApiMsProjectsService from '@/services/ms_projects_service'

const deliveryRequestsService = new BaseService(
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_URL,
  process.env.VUE_APP_MS_DELIVERY_REQUESTS_API_KEY,
)

export const state = {
  loading: false,
  allRegions: [],
  dataTalanaSap: {
    total: 0,
    pages: 0,
    items: []
  },
  businessUnits: []
}

export const getters = {
  loading: (state) => state.loading,
  allRegions: (state) => state.allRegions,
  dataTalanaSap: (state) => state.dataTalanaSap,
  businessUnits: (state) => state.businessUnits
}

export const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_REGIONS(state, payload){
    state.allRegions = payload
  },
  SET_DATA_TALANA_SAP(state, payload){
    state.dataTalanaSap = payload
  } ,
  SET_BUSINESS_UNITS(state, payload){
    state.businessUnits = payload
  }
}

export const actions = {
  async getRegions({ commit }) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/supplier_creation/regiones`)).data
      commit('SET_REGIONS', resp.regiones)
      return resp
    } catch (error) {
      return error
    }
  },
  async getAllDataTalanaSap({ commit }, payload) {
    try {
      const resp = (await deliveryRequestsService.get(
        `/api/get_talana_logs`,
        payload
      )).data
      commit('SET_DATA_TALANA_SAP', resp)
      return resp
    } catch (error) {
      return error
    }
  },
  async getBusinessUnits({ commit }, payload = {}) {    
    try {
      const resp = (await ApiMsProjectsService.get(
        `/documents/business_units`,
        payload
      )).data
      commit('SET_BUSINESS_UNITS', resp)
      return resp
    }   
    catch (error) {
      return error
    }
  },  
}
